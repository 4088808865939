import React, { useState, useEffect } from 'react';
import Masonry from 'react-masonry-css';
import Section from '../../components/Section/Section';
import NewsFilter from '../../components/NewsFilter/NewsFilter';
import NewsArticleCard from '../../components/NewsArticleCard/NewsArticleCard';
import { SyncLoader } from 'react-spinners';
import './NewsFeed.scss';

const breakpointColumnsObj = {
  default: 3,
  1200: 3,
  1024: 2,
  768: 1
};

const NewsFeed = () => {
  const [featuredArticle, setFeaturedArticle] = useState(false);
  const [newsCount, setNewsCount] = useState(14);
  const [news, setNews] = useState([]);
  const [labels, setLabels] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(false);

  let CMSURL = 'http:/localhost:8888/rare/mlc-cms';
  // CMSURL = 'https://cms.mlc.wa.edu.au/';
  process.env.NODE_ENV === 'production' ? CMSURL = 'https://cms.mlc.wa.edu.au/' : CMSURL = 'http://mlc.local';

  // TODO - Add news article card fade in on load
  // TODO - Get the total news articles and once 'newsCount' is greater or equal to the total, hide the 'Load More' button

  async function fetchNews(showLoader = true, filter = false, resetCount = false) {
    if(showLoader) {
      setLoading(true);
    }

    await fetch(`${CMSURL}/wp-json/wp/v2/categories?per_page=100`)
    .then((res) => {
      return res.json();
    })
    .then((data) => {
      const labels = [];
      data.forEach((category) => {
        if (category.parent === 0 && category.name !== "Uncategorised") {
          labels.push(category);
        }
      })

      setLabels(labels);
    }).catch((err) => {
      console.error(err);
    });

    await fetch(`${CMSURL}/wp-json/wp/v2/stories?acf_format=standard${filter ? `&categories=${filter}` : ''}&per_page=100`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const currentlyFeatured = data.filter(({ acf: { featured_article } }) => featured_article === true)[0];
        if(!filter) {
          setFeaturedArticle(currentlyFeatured);
          setNews(data.filter(({ id }) => id !== currentlyFeatured.id));

        } else {
          setFeaturedArticle(false);
          setNews(data);
        }

        if(resetCount) {
          setNewsCount(filter ? 6 : 14);
        }

      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setError(false);

        if(showLoader) {
          setLoading(false);
        }
      });

  }


  // ? Initial & on filter update fetch
  useEffect(() => {
    fetchNews(true, selectedFilter, true);
  },[selectedFilter]);

  // ? Load more news articles
  useEffect(() => {
    if(newsCount !== 6) {
      fetchNews(false, selectedFilter, false);
    }
  },[newsCount]);

  return (
    <Section classes="news-feed">
      <div className="container">
      {featuredArticle &&
      <div className="news-feed__featured">
        <NewsArticleCard
          article={featuredArticle}
          size="large"/>
      </div>}
      <NewsFilter selectedFilter={selectedFilter} setSelectedFilter={setSelectedFilter} />
      {loading ?
        <SyncLoader className="news-feed__loader"/> :
        <>
        {news.length ?<Masonry
          breakpointCols={breakpointColumnsObj}
          className="my-masonry-grid"
          columnClassName="my-masonry-grid_column">
          {/* ? access ACF fields to determine the grid item type? */}
          {news.map((item,index) => (
            index < newsCount &&
            <NewsArticleCard
              key={item.id}
              article={item}
            />
          ))}
        </Masonry> : <p style={{'textAlign': 'center'}}>No stories not found!</p>}
        {newsCount < news.length &&
        <div className={`news-feed__load-more${loading ? '' : ' loaded'}`}>
          <button onClick={() => {setNewsCount(newsCount + 6);}} className="">Load More</button>
        </div>}
      </>
      }
      </div>
    </Section>
   );
}

export default NewsFeed;