import React, { useRef, useEffect, useState } from 'react';
import CustomSelect from '../CustomSelect/CustomSelect';
import { SyncLoader } from 'react-spinners';
import './NewsFilter.scss';

const NewsFilter = ({ selectedFilter, setSelectedFilter }) => {
  const [parentFilter, setParentFilter] = useState([]);
  const [selectedParentFilter, setSelectedParentFilter] = useState({optionName: 'Categories'});
  const [childFilter, setChildFilter] = useState([]);
  const [sortedChildFilter, setSortedChildFilter] = useState([]);
  const [selectedChildFilter, setSelectedChildFilter] = useState({optionName: 'All'});
  const [loading, setLoading] = useState(true);

  let CMSURL = 'http:/localhost:8888/rare/mlc-cms';
  // CMSURL = 'https://cms.mlc.wa.edu.au/';
  process.env.NODE_ENV === 'production' ? CMSURL = 'https://cms.mlc.wa.edu.au/' : CMSURL = 'http://mlc.local';

  async function fetchCategories() {
    setLoading(true);
    await fetch(`${CMSURL}/wp-json/wp/v2/categories?per_page=100`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        const parentCategories = [{optionName: 'All', optionValue: 'all'}];
        const childCategories = [];

        data.forEach((item) => {
          if (item.parent !== 0) {
            childCategories.push(item);
          } else if(item.name !== 'Uncategorised') {
            parentCategories.push(item);
          }
        });

        setParentFilter(parentCategories);
        setChildFilter(childCategories);
      })
      .catch((err) => {
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchCategories()
  },[]);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const category = urlParams.get('category');
    const selectedCategory = parentFilter.find((item) => item.slug === category);

    if (selectedCategory) {
      setSelectedParentFilter(selectedCategory);
    }
  },[parentFilter]);

  // ? Sort child categories by selected parent category
  useEffect(() => {
    let childOptions = [{optionName: 'All', optionValue: 'all'}];

    switch (selectedParentFilter.name) {
      case 'Voices':
        setSelectedChildFilter({optionName: 'Connection'})
        break;
      case 'Podcasts':
        setSelectedChildFilter({optionName: 'Series'})
        break;
      default:
        setSelectedChildFilter({optionName: 'All'})
    }

    childFilter.forEach((item) => {
      if (item.parent === selectedParentFilter.id) {
        childOptions.push(item);
      }
    });

    setSortedChildFilter(childOptions);
  },[selectedParentFilter]);

  // ? Send the selected filter to the parent component
  useEffect(() => {
    if(selectedParentFilter.id && selectedChildFilter.id) {
      setSelectedFilter([selectedChildFilter?.id]);
    } else if(selectedParentFilter?.id) {
      setSelectedFilter([selectedParentFilter?.id]);
    } else {
      setSelectedFilter(false);
    }
  },[selectedParentFilter,selectedChildFilter]);

  return (
    <div className="news-filter">
      <h3 className="news-filter__heading">Filter by</h3>
      <div className="news-filter__filters">
        {loading ?
          <></> :
          <>
            <CustomSelect
              id="1"
              options={parentFilter}
              selectedOption={selectedParentFilter}
              setSelectedOption={setSelectedParentFilter}
            />
            <CustomSelect
              id="2"
              options={sortedChildFilter}
              selectedOption={selectedChildFilter}
              setSelectedOption={setSelectedChildFilter} />
          </>
          }
      </div>
    </div>
   );
}

export default NewsFilter;